import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL;

export default {

  async generateSpecificRegistrationJwt(plateform, userRole) {
    const response = await axios.get(apiUrl + `/v1/auth/specific-registration/jwt/${plateform}/${userRole}`);
    return response.data.authReqToken
  },

  async generateSpecificRegistrationJwtForObendy(plateform, username, token) {
    const response = await axios.get(apiUrl + `/v1/auth/specific-registration/jwt/obendy/${plateform}/${username}/${token}`);
    return response.data.authReqToken
  },

  async checkUserEmail(email) {
    return await axios.get(apiUrl + "/v1/auth/user/" + email);
  },

  async login({ email, password, issuer }) {
    return await axios.post(
      process.env.VUE_APP_API_URL + "/v1/auth/login",
      {
        email, password, issuer
      }
    );
  },

  async firstConnectionCGUValidate({ email, password }) {
    return await axios.patch(
      process.env.VUE_APP_API_URL + '/v1/auth/first/cgu-validate',
      {
        email, password
      }
    )
  },

  async registration({
    firstname,
    lastname,
    email,
    password,
    origin,
    registrationUrl,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    partnership,
    partnershipUsername,
    obendyToken,
  }) {
    return await axios.post(
      process.env.VUE_APP_API_URL + "/v1/auth/registration",
      {
        firstname,
        lastname,
        email,
        password,
        origin,
        registrationUrl,
        cguValidate: new Date(),
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        partnership,
        partnershipUsername,
        obendyToken,
      }
    );
  },

  async generateProfileJwt({ audience, profile_token, sessionJwt, userRole }) {
    return await axios.post(
      process.env.VUE_APP_API_URL + "/v1/auth/profile-jwt",
      {
        audience, profile_token, userRole
      },
      {
        headers: {
          Authorization: `Bearer ${sessionJwt}`,
        },
      }
    );
  },

  async sendPasswordForgottenMail({ auth_req_jwt, email, redirect_uri, profile_token, iss }) {
    return await axios.post(
      process.env.VUE_APP_API_URL + "/v1/auth/password/forgotten",
      {
        email,
        redirect_uri,
        profile_token,
        iss
      },
      {
        headers: {
          Authorization: `Bearer ${auth_req_jwt}`,
        },
      }
    );
  },

  async resetPassword({ auth_req_jwt, password }) {
    return await axios.put(
      process.env.VUE_APP_API_URL + "/v1/auth/password/reset",
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${auth_req_jwt}`,
        },
      }
    );
  },

  async checkEmail(email) {
    return await axios.post(
      process.env.VUE_APP_API_URL + "/check-email-validity", {
        email
      }
    );
  },
  async updateEmailableLog(id, {email_chosen}) {
    return await axios.patch(
      process.env.VUE_APP_API_URL + "/emailable-logs/" + id, {
        email_chosen
      }
    );
  }
}