<template>
  <router-view />
</template>

<script>
import { useMeta } from "quasar";
import { onMounted } from 'vue';

export default {
  setup() {
    
    let script = undefined;
    if (process.env.NODE_ENV === 'production') {
      script = {
        hotjar: {
          type: 'application/javascript',
          innerHTML: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3463711,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
        }
      }
    }

    useMeta({
      title: "A1 Connect",
      // JS tags
      script,
    });

    onMounted(() => {
      
    })
  },
};
</script>

<style>
/* Custom class to disable hover display for buttons */
.q-btn.btn--no-hover .q-focus-helper {
  display: none;
}

.q-btn.weight-regular {
  font-weight: 500;
}

.q-btn.text-underline {
  text-decoration: underline;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #353535;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
