export default {
    jwt: {
        decodeUnable: 'Impossible de décoder le JWT'
    },
    title: {
        orientation: 'ACCÈDE À',
        registration: 'CRÉÉ TON COMPTE',
        connection: 'CONNECTE TOI',
        forgottenPwd: 'MOT DE PASSE OUBLIÉ',
        resetPwd: 'METTRE À JOUR VOTRE MOT DE PASSE',
    },
    rules: {
        input: {
            required: 'Champ obligatoire'
        },
        password: {
            hint: '8 caractères minimum, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.',
            required: 'Mot de passe obligatoire',
            invalid: '8 caractères minimum, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.'
        },
        email: {
            required: 'Email obligatoire',
            invalid: 'Email invalide'
        }
    },
    inputs: {
        labels: {
            email: 'Adresse email',
            password: 'Mot de passe',
            confirmPwd: 'Confirmer le mot de passe',
            firstname: 'Prénom',
            lastname: 'Nom',
            newPwd: 'Nouveau mot de passe',
        },
    },
    buttons: {
        labels: {
            orientation: 'Suivant',
            registration: 'S\'inscrire',
            connection: 'Se connecter',
            passwordForgotten: 'Mot de passe oublié ?',
            sendResetPwdEmail: 'Envoyer',
            resetPwd: 'Mettre à jour',
            validate: 'Valider',
        }
    },
    checkboxes: {
        labels: {
            registrationCGU: 'J\'AI LU ET J\'ACCEPTE LES CGU ET POLITIQUE DE CONFIDENTIALITÉ'
        }
    },
    loadings: {
        connecting: 'Connexion en cours'
    },
    notifications: {
        incorrectConfirmedPwd: 'Confirmation du mot de passe incorrecte',
        unvalidatedCGU: 'CGU non validées'
    },
    dates: {
        days: [
            'Dimanche',
            'Lundi',
            'Mardi',
            'Mercredi',
            'Jeudi',
            'Vendredi',
            'Samedi',
        ],
        daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        months: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
        ],
        monthsShort: [
            'Jan',
            'Fév',
            'Mar',
            'Avr',
            'Mai',
            'Juin',
            'Juil',
            'Aoû',
            'Sep',
            'Oct',
            'Nov',
            'Déc',
        ]
    }
}