
import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import Meta from 'quasar/src/plugins/meta/Meta.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';import Cookies from 'quasar/src/plugins/cookies/Cookies.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import QSpinnerCube from 'quasar/src/components/spinner/QSpinnerCube.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: { loading: { spinner: QSpinnerCube } },
  plugins: {
    Notify, LocalStorage, SessionStorage, Meta, Cookies, Loading
  },
  cssAddon: true
}